<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="s-pub-tt s-pub-ttt flex">
            <span class="bt">大厅展示</span>
          </div>
          <div class="detail flex-11">
            <div class="col">
              <div class="info1">
                <img :src="imgUrl" alt="" />
              </div>
            </div>
            <div class="col col1">
              <div class="info2"></div>
              <div class="info3"></div>
            </div>
          </div>
        </div>
        <div class="mod2 flex flex-v">
          <div class="s-pub-tt flex">
            <span class="bt">服务内容</span>
          </div>

          <div class="s-scroll-list flex-1 flex flex-v">
            <div class="row row-t">
              <div style="width: 25%; text-align: center">{{serviceContent.head1}}</div>
              <div style="width: 25%; text-align: center">{{serviceContent.head2}}</div>
              <!-- <div style="width: 25%; text-align: center">{{serviceContent.head3}}</div> -->
              <div style="width: 50%; text-align: center">{{serviceContent.head4}}</div>
            </div>
            <vue-seamless-scroll
              :data="serviceList"
              :class-option="scrollOption"
              class="flex-1"
            >
              <div class="scrollbox">
                <div
                  class="row row-b"
                  v-for="(item, index) in serviceList"
                  :key="index"
                >
                  <div style="width: 25%; text-align: center">
                    {{ item.title }}
                  </div>
                  <div style="width: 25%; text-align: center">
                    {{ item.people }}
                  </div>
                  <!-- <div style="width: 25%; text-align: center">
                    {{ item.people }}
                  </div>
                  <div style="width: 25%; text-align: center">
                    {{ item.status }}
                  </div> -->
                  <div style="width: 50%; text-align: center">
                    {{ item.time }}
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div>
          <ul class="cen-list cen-list-1">
            <li class="item1">
              <h6 class="title">月办理量<em>{{mounthNum}}</em></h6>
              <!-- <div class="txt">53,940</div> -->
            </li>
            <li class="item">
              <h6 class="title">年办理量<em>{{yearNum}}</em></h6>
              <!-- <div class="txt">186,000</div> -->
            </li>
          </ul>
          <ul class="cen-list cen-list-2">
            <li class="item1">
              <h6 class="title">完成率<em>{{finish}}</em><em>%</em></h6>
              <!-- <div class="txt">37,181/37,200</div> -->
            </li>
            <li>
              <h6 class="title">环比<em>{{mounthRatio}}</em><em>%</em></h6>
              <!-- <div class="txt">37,200</div> -->
            </li>
          </ul>
        </div>
        <div class="circle-container">
          <div class="circle circle1">
            <div class="circle_item">
              <div class="circle_top">户籍业务</div>
              <div class="circle_sec">
                <span class="circle_num">{{centerVal.domicileBus}}</span>
                <span class="circle_tip">件</span>
              </div>
            </div>
          </div>
          <div class="circle circle2">
            <div class="circle_item">
              <div class="circle_top">医保业务</div>
              <div class="circle_sec">
                <span class="circle_num">{{centerVal.medicalBus}}</span>
                <span class="circle_tip">件</span>
              </div>
            </div>
          </div>
          <div class="circle circle3">
            <div class="circle_item">
              <div class="circle_top">新生儿业务</div>
              <div class="circle_sec">
                <span class="circle_num">{{centerVal.babyBus}}</span>
                <span class="circle_tip">件</span>
              </div>
            </div>
          </div>
          <div class="circle circle4">
            <div class="circle_item">
              <div class="circle_top">民政业务</div>
              <div class="circle_sec">
                <span class="circle_num">{{centerVal.civilBus}}</span>
                <span class="circle_tip">件</span>
              </div>
            </div>
          </div>
          <div class="circle circle5">
            <div class="circle_item">
              <div class="circle_top">殡葬业务</div>
              <div class="circle_sec">
                <span class="circle_num">{{centerVal.dieBus}}</span>
                <span class="circle_tip">件</span>
              </div>
            </div>
          </div>
          <div class="circle circle6">
            <div class="circle_item">
              <div class="circle_top">社保业务</div>
              <div class="circle_sec">
                <span class="circle_num">{{centerVal.socialBus}}</span>
                <span class="circle_tip">件</span>
              </div>
            </div>
          </div>
          <div class="circle circle7">
            <div class="circle_item">
              <div class="circle_top">新农保业务</div>
              <div class="circle_sec">
                <span class="circle_num">{{centerVal.newMediaBus}}</span>
                <span class="circle_tip">件</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod mod3">
          <div class="s-pub-tt flex">
            <span class="bt">把握时代机遇 坚定信心决心</span>
          </div>
          <div class="txt-container">
            <div class="txt">
             {{zuiThree}}
            </div>
          </div>
          <!-- <dv-border-box-8 style="width:100%;height:100%;">

          </dv-border-box-8> -->
        </div>
        <div class="mod mod4">
          <div class="s-pub-tt flex">
            <span class="bt">服务类别</span>
          </div>
          <div class="detail flex-11">
            <div class="col">
              <div class="b-smtit flex">
                <span class="bt">月办理</span>
              </div>
              <ul class="bar-list">
                <li class="row" v-for="(item, index) in mounthCheck" :key="index">
                  <span class="num">{{ index + 1 }}</span>
                  <div class="smbox">
                    <div class="font">
                      <p>{{ item.name }}</p>
                      <p style="color: rgb(243, 151, 32); margin: 0 10px">
                        {{ item.widthVar }}
                      </p>
                      <p>{{ item.num }}</p>
                    </div>
                    <div class="progress progress1">
                      <i :style="{ width: item.widthVar }"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col">
              <div class="b-smtit flex">
                <span class="bt">年办理</span>
              </div>
              <ul class="bar-list bar-list1">
                <li class="row" v-for="(item, index) in yearCheck" :key="index">
                  <span class="num">{{ index + 1 }}</span>
                  <div class="smbox">
                    <div class="font">
                      <span>{{ item.name }}</span>
                      <p style="color: rgb(243, 151, 32); margin: 0 10px">
                        {{ item.widthVar }}
                      </p>

                      <span>{{ item.num }}</span>
                    </div>
                    <div class="progress progress1">
                      <i :style="{ width: item.widthVar }"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mod mod5">
          <div class="s-pub-tt flex">
            <span class="bt">形象展示</span>
          </div>
          <div>
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/huiyi/320.jpg"
                    alt=""
                    class="small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/huiyi/640.jpg"
                    alt=""
                    class="big_img"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="chart" ref="chart4"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_sm.vue";
// let Echarts = require("echarts/lib/echarts");
import { borderBox1 } from "@jiaminghi/data-view";
import Vue from "vue";
Vue.use(borderBox1);
import axios from "axios";
// import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      imgUrl: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/zw_zt.webp",
      headTitle: "",
      swiper1: [],
      webData: {},
      serviceContent:{},
      serviceList:[],
      mounthNum:'',
      yearNum:'',
      finish:'',
      mounthRatio:'',
      centerVal:{},
      mounthCheck:[],
      yearCheck:[],
      zuiThree:'',
      monitorList: [
        {
          img: require("../assets/images/img_05.gif"),
          title: "榕江县水尾乡中心校正门",
        },
        {
          img: require("../assets/images/img_06.gif"),
          title: "榕江县第一中学正门",
        },
        {
          img: require("../assets/images/img_07.gif"),
          title: "榕江县示范幼儿园操场",
        },
        {
          img: require("../assets/images/img_05.gif"),
          title: "榕江县第四初级中学东门",
        },
      ],
      //   chart1: null,
      //   tootipTimer1: "",
      chart2: null,
      tootipTimer2: "",
      chart3: null,
      tootipTimer3: "",
      chart4: null,
      tootipTimer4: "",
      chart5: null,
      tootipTimer5: "",
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    this.getData()
    this.getserviceContent()
    this.getcenterVal()
    this.getserviceKind()
  },
  mounted() {
    this.swiper1 = new Swiper(".swiper-container.swiper-container1", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      speed: 3000,
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerColumn: 1,
      autoplay: 10000,
      loop: true,
      centeredSlides: true,
      slidesOffsetAfter: 100,
    });
    // this.init1();
    // this.init2();
    // this.init3();
    // this.init4();
    // this.init5();
    window.addEventListener("resize", () => {
      // this.chart1.resize();
      // this.chart2.resize();
      // this.chart3.resize();
      // this.chart4.resize();
      // this.chart5.resize();
    });
  },

  methods: {
    getData() {
      axios
        .get("/10kszhzw.json",{
          params:{
            // timestamp:new Date().getTime()
            random:Math.random()
          }
        })
        .then((res) => {

          // 处理响应
          // console.log("数据:"+res.data);
          let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
          // console.log(dataObject.data);
          //
          let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
          // console.log(jsonString) // 输出字符串类型的 JSON 数据
          let jsonObject = JSON.parse(jsonString);
          this.webData = jsonObject;
          //标题
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },
    async getserviceContent() {
      const res = await axios.get("/10kszhzw.json");
      let dataObject = res.data;
      this.headTitle = dataObject.bigTitle
      console.log('this.headTitle',this.headTitle);
      this.serviceContent = dataObject.serviceContent
      this.serviceList = dataObject.serviceContent.serviceList
    },
    async getcenterVal(){
      const res = await axios.get("/10kszhzw.json");
      let dataObject = res.data;
      this.mounthNum = dataObject.mounthNum
      this.yearNum = dataObject.yearNum
      this.finish = dataObject.finish
      this.mounthRatio = dataObject.mounthRatio
      this.centerVal = dataObject.centerVal
      console.log('this.centerVal====>',this.centerVal);
    },
    async getserviceKind(){
      const res = await axios.get("/10kszhzw.json");
      let dataObject = res.data;
      this.mounthCheck = dataObject.serviceKind.mounthCheck
      this.yearCheck = dataObject.serviceKind.yearCheck
      this.zuiThree = dataObject.zuiThree
    }
  },
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/hunan/hengyang/photo/zhengwu_bg.png) no-repeat center;
  background-size: cover;
  background-color: #0a224b;
}
.bar-list .num {
  color: #FD9D20;
}
.mod1 {
  height: 44%;
  margin-bottom: 4%;
}
.mod2 {
  height: 52%;
}
.mod3 {
  /* height: 30%; */
  margin-bottom: 4%;
}
.mod4 {
  /* height: 28%; */
  margin-bottom: 4%;
}
.mod4 .detail .bar-list .progress1 {
  height: 8px;
  /* background-color: #39405d; */
  background-color: transparent;
  position: relative;
}
.mod4 .detail .bar-list .progress1 i {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(274deg, #4c8ef9 0%, rgba(59, 126, 234, 0) 100%);
  border-radius: 14px;
}
.mod5 {
  /* height: 42%; */
  margin-bottom: 4%;
}
/* .mod6 {
  height: 27%;
} */
.mod1 .s-pub-ttt {
  background: url(../assets/images/icon_02.png);
  background-size: 100% 100%;
  padding-left: 100px;
  padding-right: 25px;
  align-items: center;
  height: 148px;
  margin-bottom: 30px;
}
.mod1 .chart {
  height: calc(100% - 100px);
  flex: 1;
}
.mod1 .detail {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  grid-template-rows: 1fr 1fr 1fr;
}
.mod1 .col1 {
  display: flex;
  justify-content: space-around;
}
.mod1 .info1 {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  /* background: url(../assets/images/city_001.jpg); */
  /* background: url(https://media.shouxinfund.com/1.jpg); */
  background-size: 100% 100%;
  overflow: hidden;
}
.mod1 .info2 {
  width: 49%;
  height: 300px;
  border-radius: 10px;
  background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/zw_zt1.webp);
  background-size: 100% 100%;
  overflow: hidden;
}
.mod1 .info3 {
  width: 49%;
  height: 300px;
  border-radius: 10px;
  background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/zu_zt3.jpg);
  background-size: 100% 100%;
  overflow: hidden;
}
.mod2 {
}
.mod3 .txt-container {
  /* margin: 3% ; */
  margin: auto 0;
}
.mod3 .txt-container .txt {
  font-size: 22px;
  color: #fff;
  text-indent: 40px;
  letter-spacing: 10px;
}
.mod4 .detail {
  /* margin-top: 3%; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.mod {
  display: flex;
  flex-direction: column;
}
.mod .chart {
  height: calc(100% - 100px);
  flex: 1;
}

.cen-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding: 0 5%;
}
.cen-list li {
  /* text-align: center; */
  width: 42%;
  margin-top: 50px;
}
.cen-list .title {
  font-size: 44px;
  color: #fff;
  font-family: "RuiZiZhenYan";
  height: 70px;
  line-height: 70px;
  padding-left: 240px;
}
.cen-list.cen-list-1 .title {
  font-size: 44px;
  font-family: "RuiZiZhenYan";
  height: 70px;
  line-height: 70px;
  background: url(../assets/images/zhengwu_01.png);
  background-size: 100% 100%;
}
.cen-list.cen-list-1 .title em {
  font-size: 30px;
  font-family: "RuiZiZhenYan";
}
.cen-list.cen-list-2 .title {
  font-size: 44px;
  font-family: "RuiZiZhenYan";
  height: 70px;
  line-height: 70px;
  background: url(../assets/images/zhengwu_02.png);
  background-size: 100% 100%;
}

.cen-list .title em {
  font-size: 30px;
  font-family: "RuiZiZhenYan";
}
.cen-list .txt {
  font-size: 52px;
  color: #45f5ff;
  font-family: "PangMenZhengDao";
  margin-top: 10px;
}

.cen-list.b {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 40px;
}
.cen-list.b li {
  width: 30%;
}

.cen-map {
}
.cen-map .item {
  text-align: center;
}
.cen-map .title {
  font-size: 34px;
  color: #fff;
  margin-bottom: 15px;
}
.cen-map .tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 26px;
  color: #fd9d20;
  border: 1px solid #fd9d20;
  border-radius: 30px;
  padding: 0 25px;
  line-height: 50px;
}
.cen-map .icon {
  width: 120px;
  display: block;
  margin: 20px auto 0;
}

.cen-map .item1 {
  position: absolute;
  left: 10%;
  top: 65%;
}
.cen-map .item2 {
  position: absolute;
  left: 25%;
  top: 45%;
}
.cen-map .item3 {
  position: absolute;
  left: 30%;
  top: 28%;
}
.cen-map .item4 {
  position: absolute;
  left: 40%;
  top: 58%;
}
.con-c .circle-container {
  position: relative;
}
.con-c .circle-container .circle {
  background-image: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/hunan/hengyang/photo/zhengwu_pp.png);
  background-size: 100% 100%;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-container .circle .circle_item {
}
.circle-container .circle1 {
  position: absolute;
  margin-left: 15%;
  margin-top: 10%;
}
.circle-container .circle2 {
  position: absolute;
  left: 40%;
  top: 24%;
}
.circle-container .circle3 {
  position: absolute;
  margin-left: 66%;
  margin-top: 15%;
}
.circle-container .circle4 {
  position: absolute;
  margin-left: 28%;
  margin-top: 28%;
}
.circle-container .circle5 {
  position: absolute;
  margin-left: 57%;
  margin-top: 32%;
}
.circle-container .circle6 {
  position: absolute;
  margin-left: 5%;
  margin-top: 40%;
}
.circle-container .circle7 {
  position: absolute;
  margin-left: 77%;
  margin-top: 48%;
}
.circle .circle_item .circle_top {
  font-size: 35px;
  text-align: center;
  color: #fff;
  font-family: 锐字真言体;
  letter-spacing: 10px;
}
.circle .circle_item .circle_sec {
  margin-top: 10px;
  text-align: center;
}
.circle_sec .circle_num {
  color: #f39720;
  font-size: 44px;
}
.circle_sec .circle_tip {
  color: #fff;
  font-size: 40px;
}
.b-smtit .bt {
	font-size: 28px;
}
.swiper-container {
    /* margin-top: 12%; */
    width: 100%;
    height: 100%;
  }
  .swiper-container .swiper-slide {
    border: 2px solid #15386e;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
.swiper-container .swiper-slide img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
</style>
